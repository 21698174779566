const theme = {
  colors: {
    text: '#1f44d6'
  },
  fonts: {
    body: 'Open Sans',
    heading: 'Open Sans'
  },
  googleFont:
    'https://fonts.googleapis.com/css?family=Open+Sans:400,600,700,800&display=swap',
  styles: {
    slide: {},
    h1: {
      fontWeight: 800
    },
    h2: {
      fontWeight: 800
    }
  }
};

export default theme;

import { Split, SplitRight, Horizontal, Image } from 'mdx-deck';
import { swiss } from '@mdx-deck/themes';
import theme from "../../../../../../theme";
import benchmarking1 from "../../../../../../static/benchmarking/benchmarking 1.png";
import benchmarking2 from "../../../../../../static/benchmarking/benchmarking 2.png";
import benchmarking3 from "../../../../../../static/benchmarking/benchmarking 3.png";
import benchmarking4 from "../../../../../../static/benchmarking/benchmarking 4.png";
import benchmarking5 from "../../../../../../static/benchmarking/benchmarking 5.png";
import responsive from "../../../../../../static/benchmarking/responsive-web.png";
import responsive2 from "../../../../../../static/benchmarking/responsive-web-2.png";
import messaging1 from "../../../../../../static/messaging/Messaging 1.png";
import messaging2 from "../../../../../../static/messaging/Messaging 2.png";
import messaging3 from "../../../../../../static/messaging/Messaging 3.png";
import navigation1 from "../../../../../../static/navigation/UI pattern - navigation 1.png";
import navigation2 from "../../../../../../static/navigation/UI pattern - navigation 2.png";
import navigation3 from "../../../../../../static/navigation/UI pattern - navigation 3.png";
import pattern1 from "../../../../../../static/patterns/UI pattern 1.png";
import pattern2 from "../../../../../../static/patterns/UI pattern 2.png";
import pattern3 from "../../../../../../static/patterns/UI pattern 3.png";
import pattern4 from "../../../../../../static/patterns/UI pattern 4.png";
import proposal1 from "../../../../../../static/proposal/Baggage.png";
import proposal2 from "../../../../../../static/proposal/Baggage-first-iteration.png";
import proposal3 from "../../../../../../static/proposal/Baggage-second-iteration-1.png";
import proposal4 from "../../../../../../static/proposal/Baggage-second-iteration-2.png";
import proposal5 from "../../../../../../static/proposal/Baggage-second-iteration-3.png";
import proposal6 from "../../../../../../static/proposal/Baggage-second-iteration-4.png";
import proposal7 from "../../../../../../static/proposal/Insurance.png";
import proposal8 from "../../../../../../static/proposal/Insurance-details.png";
import React from 'react';
export default {
  Split: Split,
  SplitRight: SplitRight,
  Horizontal: Horizontal,
  Image: Image,
  swiss: swiss,
  theme: theme,
  benchmarking1: benchmarking1,
  benchmarking2: benchmarking2,
  benchmarking3: benchmarking3,
  benchmarking4: benchmarking4,
  benchmarking5: benchmarking5,
  responsive: responsive,
  responsive2: responsive2,
  messaging1: messaging1,
  messaging2: messaging2,
  messaging3: messaging3,
  navigation1: navigation1,
  navigation2: navigation2,
  navigation3: navigation3,
  pattern1: pattern1,
  pattern2: pattern2,
  pattern3: pattern3,
  pattern4: pattern4,
  proposal1: proposal1,
  proposal2: proposal2,
  proposal3: proposal3,
  proposal4: proposal4,
  proposal5: proposal5,
  proposal6: proposal6,
  proposal7: proposal7,
  proposal8: proposal8,
  React: React
};